import React, { useState } from "react"
import {
  ZoomInIcon,
  MinusIcon,
  PlusIcon,
  LinkIcon,
} from "@heroicons/react/solid"
import { Disclosure } from "@headlessui/react"

export default function CostumeCard({ costume, setModalImage, setShowModal }) {
  const details = ["Hair", "Shoes", "Tights", "Earrings", "Makeup", "Others"]

  return (
    <div className="bg-white rounded-lg mb-5">
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 p-3">
        <li
          key={costume.id}
          className="col-span-1 flex flex-col justify-start text-center bg-white rounded-lg md:border md:border-gray-50 md:shadow-sm divide-y divide-gray-200"
        >
          <div className="flex-1 flex flex-col p-3 md:p-3 lg:p-8">
            <div
              className="relative w-48 h-48 mx-auto bg-cover rounded-lg cursor-pointer"
              style={{
                backgroundImage: `url(${
                  costume.images[0]?.thumbnails?.large?.url || costume.images
                })`,
              }}
              onClick={() => {
                setModalImage(
                  costume.images[0]?.thumbnails?.large?.url || costume.images
                )
                setShowModal(true)
              }}
            >
              <ZoomInIcon
                className="w-5 h-5 text-ascendance absolute top-2 right-2"
                aria-hidden="true"
              />
            </div>
            <h3 className="mt-4 text-gray-900 text-xl font-medium">
              {costume.class}
            </h3>
            <div className="flex justify-center flex-col">
              <section aria-labelledby="details-heading" className="mt-12">
                <h2 id="details-heading" className="sr-only">
                  Additional details
                </h2>

                <div className="divide-y divide-gray-200 border-t">
                  {details.map(detail => (
                    <Disclosure as="div" key={detail}>
                      {({ open }) => (
                        <>
                          <h3>
                            <Disclosure.Button className="group relative flex w-full items-center justify-between py-4 text-left">
                              <span
                                className={classNames(
                                  open ? "text-ascendance" : "text-gray-900",
                                  "text-sm font-medium"
                                )}
                              >
                                {detail}
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon
                                    className="block h-6 w-6 text-ascendance group-hover:text-ascendance"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel
                            as="div"
                            className="prose prose-sm pb-6"
                          >
                            <p className="text-left text-sm">
                              {costume[detail.toLowerCase()] ||
                                "Not Applicable"}
                            </p>
                            {detail === "Shoes" && costume.shoeLink && (
                              <a
                                className="text-left text-sm text-ascendance flex mt-5 justify-start items-center gap-x-1"
                                target="_blank"
                                href={costume.shoeLink}
                              >
                                <LinkIcon className="w-3 h-3" />
                                <span>Order Shoes on Amazon</span>{" "}
                              </a>
                            )}
                            {detail === "Tights" && costume.tightsLink && (
                              <a
                                className="text-left text-sm text-ascendance flex mt-5 justify-start items-center gap-x-1"
                                target="_blank"
                                href={costume.tightsLink}
                              >
                                <LinkIcon className="w-3 h-3" />
                                <span>Order Tights on Amazon</span>{" "}
                              </a>
                            )}
                            {detail === "Others" && costume.othersLink && (
                              <div>
                                {Object.keys(costume.othersLink).map(key => (
                                  <a
                                    className="text-left text-sm text-ascendance flex mt-3 justify-start items-center gap-x-1"
                                    target="_blank"
                                    href={costume.othersLink[key]}
                                    key={costume.othersLink[key]}
                                  >
                                    <LinkIcon className="w-3 h-3" />
                                    <span>{key}</span>
                                  </a>
                                ))}
                              </div>
                            )}

                            {detail === "Hair" && (
                              <a
                                className="text-left text-sm text-ascendance flex mt-5 justify-start items-center gap-x-1"
                                target="_blank"
                                href={costume.hairVideo}
                              >
                                <LinkIcon className="w-3 h-3" />
                                <span>Hair Video Tutorial</span>
                              </a>
                            )}
                            {detail === "Makeup" && (
                              <a
                                className="text-left text-sm text-ascendance flex mt-5 justify-start items-center gap-x-1"
                                target="_blank"
                                href={costume.makeupVideo}
                              >
                                <LinkIcon className="w-3 h-3" />
                                <span>Makeup Video Tutorial</span>
                              </a>
                            )}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </div>
              </section>

              <p className="text-red-500 mt-1">
                Due to constant changes in costume availability and sizes, all
                costumes are subject to change without notice.
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}
