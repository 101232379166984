import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Modal from "../components/Modal/Modal"
import CostumeCard from "../components/CostumeCard/SingleCostumeCard"
import LoadingImage from "../images/loading.svg"
import NoResults from "../images/noResults.svg"
import * as queryString from "query-string"
import { useForm } from "react-hook-form"

import { navigate } from "gatsby"

const Costumes = ({ location }) => {
  const { id, name } = queryString.parse(location.search)
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(false)
  const [links, setLinks] = useState({})
  const [image, setImage] = useState(null)
  const [apiName, setApiName] = useState(null)
  const [pictureRes, setPictureRes] = useState(null)
  const [size, setSize] = useState(null)

  const getLinks = async (id, idType = "auto") => {
    try {
      const res = await fetch(
        `/.netlify/functions/getFormsById?id=${id}&idType=${idType}`
      )
      const data = await res.json()
      if (data.error) {
        setError(true)
      } else {
        setLinks(data.urls)
        if (data.name) {
          setApiName(data.name)
        }
        if (data.image.length) {
          setImage(data.image[0]?.thumbnails?.large?.url)
        }

        if (data.pictureRes) {
          setPictureRes(data.pictureRes)
        }

        if (data.size) {
          setSize(data.size)
        }

        setError(false)
        setLoading(false)
      }
    } catch (e) {
      console.log(e)
      setLoading(false)
      setError(true)
    }
  }

  useEffect(() => {
    if (id) {
      getLinks(id)
    }
  }, [])

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm()

  const onSubmit = async formdata => {
    resetField("tagId")
    getLinks(formdata.tagId, "nfcTag")
  }

  return (
    <Layout>
      {!id && (
        <div className="container mx-auto pt-8 mb-4 md:my-2">
          <form
            className="flex flex-col  md:flex-col my-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col md:flex-row items-start">
              <label className="flex md:items-center flex-col md:flex-row text-sm md:text-lg text-gray-600">
                Tag ID:
                <span>
                  <input
                    type="text"
                    autoFocus
                    className="pl-2 mb-2 md:mb-0 md:ml-2 h-10 rounded-md"
                    name="tagId"
                    maxLength="10"
                    {...register("tagId", { required: true })}
                  />
                </span>
              </label>

              <button
                className="md:ml-3 bg-ascendance text-white px-2 md:px-4 py-2 rounded-md"
                type="submit"
              >
                Search
              </button>
            </div>
          </form>
        </div>
      )}
      {error && (
        <div className="flex flex-col justify-center items-center">
          <h2 className="text-ascendance text-4xl font-semibold mb-10">
            No Results Found
          </h2>
          {console.log(error)}
          <img
            className="w-96"
            style={{ display: "block", margin: "auto" }}
            alt="loading image animation"
            src={NoResults}
          />
        </div>
      )}

      {!error && (
        <div className="container py-2 px-1 mx-auto md:min-h-full">
          <div className="mt-8 mb-4 md:my-2 mx-2">
            <h1 className="text-4xl md:mb-5 md:text-5xl text-gray-800 font-bold ">
              {name} {apiName}
            </h1>
          </div>

          {pictureRes && (
            <p>
              <span className="font-bold">Picture Reservation:</span>{" "}
              {pictureRes}
            </p>
          )}
          {size && (
            <p>
              <span className="font-bold">Size:</span> {size}
            </p>
          )}

          {image && (
            <img
              className="mb-10 mt-5  w-full md:w-auto border-ascendance rounded-md border-2"
              src={image}
            />
          )}

          {Object.keys(links).length > 0 && (
            <div className="flex flex-col gap-10">
              <a
                className="bg-ascendance text-white px-5 py-10 rounded block text-center text-xl"
                href={links?.dismissal}
              >
                Dismissal Picture Form
              </a>
              <a
                className="bg-indigo-600 text-white px-5 py-10 rounded block text-center text-xl"
                href={links?.pictureDay}
              >
                Picture Day Comments
              </a>
            </div>
          )}
        </div>
      )}
    </Layout>
  )
}

export default Costumes
